import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

const media = {
  secTitle: 'The Effects of Generational Inequities',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/q2H7vOH76uY',
  },
};

export default () => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      Pain and trauma caused by racial inequities are mutually reinforcing,
      creating ripple effects across generations.
    </p>
    <p>
      Southern residents we talked to are very attuned to the ways in which
      traumatic experiences, driven by racism and poverty, are absorbed and
      passed down from one generation to the next; this social context often
      molds the horizons and lifelong trajectories of young people. The
      emotional and psychological toll of decades of exclusion, hatred, and
      violence experienced by communities of color and economically marginalized
      communities has long gone unacknowledged, and therefore unaddressed.{' '}
    </p>
    <Quote
      quote="Another barrier is just the mentality, it’s the mentality of living under this weight of racism for half a century and just you can’t get away from that, you cannot get away from it. There’s a lot of distrust."
      person="Black female, 30"
      title="Charlotte"
    />
    <Quote
      quote="What you see now is a product of a system. There are things embedded in that system that dealt with self-perception for African-Americans, and the culmination of those things is-- ‘How do I view myself in relationship to others, and how do I view others?’"
      person="White Female"
      title="Sunflower County"
    />
    <Quote
      quote="I do a lot of work with generational trauma...and one of the characteristics is ever-present anger. Like, low-grade anger, but most of it is because of cut off opportunities."
      person="White female"
      title="Richmond"
    />
    <Quote
      quote="But it all comes full circle because the education, where you live, the crime, all of that, and what plays a big role that people don’t talk about a lot is the trauma that kids experience."
      person="Black female, 65"
      title="Richmond"
    />
  </Modal>
);
